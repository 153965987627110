import React, { useEffect, useCallback } from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import Order from 'model/order';
import { useParams } from "react-router-dom";
import { perms, useAccess } from "context/access";
import { useDispatch, useSelector } from "react-redux";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import { getVIDStatusImage } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { doOrderMessagesCleanup, doOrderSingleCleanup, getChatMembers, getOrderMessages, getOrderWithCustomerSigners } from "store/actions";
import socketEvent from "constants/socketEvent";
import "react-perfect-scrollbar/dist/css/styles.css";
import ChatConversation from "components/Shared/Chat/Conversation";
import ChatSidebar from "components/Shared/Chat/Sidebar";
import useAllowedChannels from "hooks/allowedChannels";
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import coSigners from 'assets/images/co-signer-icon.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';

const ViewMessages = () => {

  let { id, channel } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { messages, isLoadInProgress: isMessagesLoadInProgress, messagesError, members, unreadMessagesCount } = useSelector(state => state.Order.Messages);

  // hook that gets the list of channels the user has access to, considering his role and the type of order
  const channels = useAllowedChannels(order?.isNotaryRequired);

  /********** OTHER /**********/

  const getAllMessages = useCallback(() => {
    Object.keys(channels).map(chId => {
      dispatch(getOrderMessages(id, chId));
    });
  }, [id, channels]);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => getAllMessages(), [getAllMessages]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  useEffect(() => {
    // make the initial remote call to get the order data and messages
    refreshOrder();

    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  useEffect(() => {
    // we need to fetch all messages from the start so we can display the latest message in the sidebar
    getAllMessages();
    return () => {
      dispatch(doOrderMessagesCleanup());
    }
  }, [id, channels]);

  useEffect(() => {
    // fetch chat members
    refreshChatMembers();
  }, [id]);

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getOrderWithCustomerSigners(id));

  const refreshChatMembers = () => dispatch(getChatMembers(id, channel));


  if (!!order && !isLoadInProgress && !!channels && Object.keys(channels).length > 0 && !Object.keys(channels).find(id => id === channel)) {
    return <Error title="Channel not found" />
  }

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {!!order && Object.keys(channels).length > 0 && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col lg="12">
              <div className="chat-breadcrumbs">
                {order.schedulerFullName && <span>Scheduler Name: <strong>{order.schedulerFullName},  </strong></span>}
                {order.orderNotary && <span>Notary Name: <strong>{order.orderNotary?.notaryFullName},</strong> </span>}
                {order.id && <span>Order ID:<strong> # {order.id}, </strong></span>}
                {order.id &&
                  <span>Services Included: </span>
                }
                {order.isVidRequired && getVIDStatusImage(order.vidStatus, order)}
                {order.isEsignRequired && <><img id={`e-sign${order.id}`} width={20} src={eSignServiceIcon} className="me-0" /><UncontrolledTooltip placement="bottom" target={`e-sign${order.id}`}>E sign</UncontrolledTooltip></>}
                {order.isInkSignRequired && <><img id={`ink-sign${order.id}`} width={20} src={inkSignServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`ink-sign${order.id}`}>Ink sign</UncontrolledTooltip></>}
                {order.isNotaryRequired && <><img id={`notary-service${order.id}`} width={20} src={notaryServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`notary-service${order.id}`}>Notary service</UncontrolledTooltip></>}
                {order.hasAdditionalSigner && <><img id={`co-signers${order.id}`} width={20} src={coSigners} className="me-0" /><UncontrolledTooltip placement="bottom" target={`co-signers${order.id}`}>Co-signers</UncontrolledTooltip></>}
                {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
                  <><img id={`doc-delivery-shipping${order.id}`} src={docDeliveryShippingIcon} width={20} className="me-1" /><UncontrolledTooltip placement="bottom" target={`doc-delivery-shipping${order.id}`}>Documents shipping</UncontrolledTooltip></>
                }{order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
                  <><img id={`doc-delivery-upload${order.id}`} src={docDeliveryUploadIcon} width={20} className="me-1" /><UncontrolledTooltip placement="bottom" target={`doc-delivery-upload${order.id}`}>Documents upload</UncontrolledTooltip></>
                }
                {order.isRushRequired && <><img id={`rush-order${order.id}`} src={rushOrderServiceIcon} witdh={20} className="me-1" /><UncontrolledTooltip placement="bottom" target={`rush-order${order.id}`}>Rush order</UncontrolledTooltip></>}
                {order.notaryMustUploadDocs && <><img id={`must-upload-docs${order.id}`} witdh={20} src={mustUploadDocsIcon} className="me-1 must-upload-icon" /><UncontrolledTooltip placement="bottom" target={`must-upload-docs${order.id}`}>Notary must upload docs</UncontrolledTooltip></>}
              </div>
            </Col>
          </Row>
          {/* Desktop Content */}
          <BrowserView className="h-100">
            <Row>
              <Col>
                <div className="d-lg-flex">
                  <ChatSidebar id={+id} channelId={+channel} messages={messages} channels={channels} order={order} unreadMessagesCount={unreadMessagesCount} />
                  <ChatConversation id={+id} order={order} channelId={+channel} messages={messages[channel]} isLoadInProgress={isMessagesLoadInProgress} members={members[channel]} messagesError={messagesError} channels={channels} />
                </div>
              </Col>
            </Row>
          </BrowserView>
          {/* Mobile Content */}
          <MobileView className="h-100">
            <ChatSidebar id={+id} channelId={+channel} messages={messages} channels={channels} order={order} unreadMessagesCount={unreadMessagesCount} />
          </MobileView>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
      {messagesError && <Error error={messagesError} title404="Messages not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: "Edit order",
  url: route(routes.view_order, order.id),
}, {
  title: "Messages",
}];

export default ViewMessages;