import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import Col from "components/Shared/Col";
import { doOrderSingleCleanup, getOrderWithCustomerSigners, patchOrder } from "store/actions";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import FormSteps from "pages/Order/Partial/Form/Steps";
import FormNewCustomer from "pages/Order/Partial/Form/New/Customer";
import Order from "model/order";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import { getDealerStore } from "helpers/backendHelper";
import { useSubscription } from "context/subscription";
import DealerGroup from "model/dealerGroup";
import useOrderServicesCheck from "hooks/orderServicesCheck";
import RouteOneBanner from "components/Shared/RouteOneBanner";

const NewCustomer = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  // read route params
  let { id } = useParams();
  // params are strings by default however we need ID as in int
  id = parseInt(id);

  // read query string
  const queryParams = new URLSearchParams(window.location.search);

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({ next: null, prev: routes.list_orders });

  // check if the step from FormSteps component was clicked
  const [isStepSelected, setIsStepSelected] = useState({});

  const handlerStepChanged = (data) => setIsStepSelected(data);

  // dealer store subscription info
  const { dealerStore } = useSubscription();

  const [storeInfo, setStoreInfo] = useState(null);

  // check if the group special instructions exist and applies to dealers
  const isGroupSpecialInstructionsAppliesTo = () => storeInfo?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);
  const showGroupSpecialInstructions = () => storeInfo?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  // check if services have been selected
  useOrderServicesCheck(order);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // this wizard step can be accessed both with and without an order id
    // initially it is accessed without an id as the order is just being created
    // then the user can navigate back to this step and update the order
    if (id) {
      // fetch the order data so we can prepopulate the form fields
      dispatch(getOrderWithCustomerSigners(id));
    } else if (!queryParams.get('di')) {
      // user must acknowledge the instructions before he can create an order
      // however there are no dealership initials in the query string
      // (most likely a direct access via url)
      // so redirect the user back to the first step
      navigate(route(routes.new_order));
    } else {
      // this is a new order that is just being created
      // so we have to set the basic info on the order to be saved along with it
      dispatch(patchOrder({
        customerLocation: parseInt(queryParams.get('cl')),
        isVidRequired: !!parseInt(queryParams.get('vi')),
        isEsignRequired: !!parseInt(queryParams.get('es')),
        isInkSignRequired: !!parseInt(queryParams.get('is')),
        isNotaryRequired: !!parseInt(queryParams.get('nt')),
        isRushRequired: !!parseInt(queryParams.get('ru')),
        dealerStoreId: parseInt(queryParams.get('ds')),
        isAoiThumbprintRequired: !!parseInt(queryParams.get('tp')),
        dealershipInitials: queryParams.get('di'),
        termsAccepted: queryParams.get('tc'),
      }));
    }
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  // runs whenever the order changes
  // which is when the order is fetched from remote
  useEffect(() => {
    // when we do 'patchOrder()', 'order' is no longer null but it is still partial (missing fields)
    // so be careful to check for id
    if (order && order.id && order.status != Order.STATUS_DRAFT) {
      // editing an order via this wizard is only allowed while the order is in DRAFT mode
      // (order is not yet submitted and the user is navigating back and forth through the wizard)
      // after that editing must be done from the view-order screen
      navigate(route(routes.view_order, order.id));
    }
  }, [order]);

  /********** OTHER **********/
  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store data
    refreshStoreInfo();
  }, [dealerStore?.id]);

  const refreshStoreInfo = () => {
    getDealerStore(dealerStore?.id)
      .then(response => {
        setStoreInfo(response.dealerStore);
      })
      .catch(ex => { })
  };

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <div className="page-content">
      {(!isLoadInProgress && !orderError) && <React.Fragment>
        <MetaTitle>New order - Customer information</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs()} />
            </Col>
            <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              {showGroupSpecialInstructions() && <SpecialInstructionsLabel instructions={storeInfo.dealerGroupSpecialInstructions} />}
            </Col>
          </Row>
          <RouteOneBanner />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 pb-4 mb-0">Create new order</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  {!!order && <FormSteps currentStep={Order.ORDER_CREATION_STEP_CUSTOMER} order={order} setNavRoutes={setNavRoutes} onChange={handlerStepChanged} />}
                  {!!order && <FormNewCustomer currentStep={Order.ORDER_CREATION_STEP_CUSTOMER} id={id} defaultValues={order} navRoutes={navRoutes} stepChanged={isStepSelected} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'ORDERS',
  url: route(routes.list_orders),
}, {
  title: 'New order',
  url: route(routes.new_order),
}, {
  title: 'Customer information',
}];

export default NewCustomer;