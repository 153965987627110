import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Table, Button } from "reactstrap";
import Col from "components/Shared/Col";
import { startSchedulerMeeting, resetOrderActionFlag } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { openInNewWindow, showError, getDealerFeeTotalCost } from 'helpers/utilHelper';
import AdditionalFee from "model/additionalFee";

const Accounting = ({ fees, order }) => {

  const totalCostValue = getDealerFeeTotalCost(fees, order?.compensationAmount, order?.additionalServicesAmount)

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="accounting-card bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Accounting</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1 text-break">
          <tbody>
            {fees?.map((item, index) => {
              if (item) {
                return (
                  <tr key={index}>
                    <td className="section-row-label">{item.additionalFeeId === AdditionalFee.ID_TRANSACTION_FEE ? 'Transaction Fee' : item.additionalFeeName}</td>
                    <td className="section-row-value text-right ps-4">{`${Number(item.price) * Number(item.count ?? 1)}$` || '--'}</td>
                  </tr>)
              }
            })}

            {!!order.additionalServicesAmount && (
              <tr>
                <td className="section-row-label">Additional Service</td>
                <td className="section-row-value text-right ps-4">{`${order.additionalServicesAmount}$` || '--'}</td>
              </tr>
            )}

            {!!order.compensationAmount && (
              <tr>
                <td className="section-row-label">Comp</td>
                <td className="section-row-value text-right ps-4">{`${order.compensationAmount}$` || '--'}</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="card-total-cost">
          <label className="total-cost-label">Total Cost</label>
          <label className="total-cost-value">{`${totalCostValue}$` || '0$'}</label>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

Accounting.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
};

export default Accounting;